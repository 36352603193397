'use client'

import { MouseEvent } from 'react'

import { useTracking } from '@marketplace-web/shared/event-tracker'
import PromoBoxComponent from '_libs/common/braze/components/PromoBox/PromoBox'
import { clickListItemEvent, ClickListItemEventArgs } from '_libs/common/event-tracker/events'

type Props = {
  tracking: ClickListItemEventArgs
} & ComponentProps<typeof PromoBoxComponent>

const TrackedPromoBox = ({ onClick, tracking, ...props }: Props) => {
  const { track } = useTracking()

  const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
    track(clickListItemEvent(tracking))

    onClick?.(event)
  }

  return <PromoBoxComponent {...props} onClick={handleClick} />
}

export default TrackedPromoBox
