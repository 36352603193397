import { useEffect } from 'react'

import { ResponseError } from '@marketplace-web/shared/api-client'
import {
  incrementPageLoadFailureCounter,
  incrementPageLoadInitiatedCounter,
  logHomeError,
} from 'pages/Home/utils/observability'

import { EMPTY_RESPONSE_ERROR } from '../utils/api'
import useTabs from './useTabs'

const useHomeObservability = () => {
  const { currentTab } = useTabs()

  useEffect(() => {
    incrementPageLoadInitiatedCounter(currentTab.name)
  }, [currentTab.name])

  const trackEmptyHome = (homeError: ResponseError) => {
    incrementPageLoadFailureCounter({
      tab: currentTab.name,
      homepage_reason:
        homeError === EMPTY_RESPONSE_ERROR ? homeError.message : String(homeError.status),
    })
  }

  const trackBoundaryError = (boundaryError: Error | null) => {
    incrementPageLoadFailureCounter({ tab: currentTab.name, homepage_reason: 'boundary' })
    logHomeError(boundaryError)
  }

  return {
    trackEmptyHome,
    trackBoundaryError,
  }
}

export default useHomeObservability
