'use client'

import { ChangeEvent, MouseEvent, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Button, Cell, Container, Divider, Radio, Spacer, Text } from '@vinted/web-ui'
import { X16 } from '@vinted/monochrome-icons'

import { useBreakpoint } from '@marketplace-web/shared/breakpoints'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { useTranslate } from '@marketplace-web/shared/i18n'
import { useSession } from '@marketplace-web/shared/session'
import { Banner, DeprecatedInputTextarea } from '@marketplace-web/shared/ui-helpers'

import { ClickableElement } from 'constants/tracking/clickable-elements'
import { ViewableElement } from 'constants/tracking/viewable-elements'
import { deleteNpsSurvey, sendNpsSurveyResponse, setBannerAsSeen } from 'data/api'
import { BannerType } from '@marketplace-web/domain/banners'
import { clickEvent, viewEvent } from '_libs/common/event-tracker/events'

const NetPromoterScore = () => {
  const [selectedRating, setSelectedRating] = useState<number | null>(null)
  const [showSurvey, setShowSurvey] = useState(true)
  const [showCommentBox, setShowCommentBox] = useState(false)
  const [comment, setComment] = useState<string | null>(null)

  const { track } = useTracking()
  const translate = useTranslate()
  const { user } = useSession()
  const breakpoints = useBreakpoint()

  useEffect(() => {
    setBannerAsSeen({ type: BannerType.Nps })
    track(viewEvent({ target: ViewableElement.NpsSurvey }))
  }, [track])

  const handleRatingSelect = (newRating: number) => (event: MouseEvent<HTMLInputElement>) => {
    event.stopPropagation()
    setSelectedRating(newRating)
    setShowCommentBox(true)
  }

  const handleCommentInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setComment(event.target.value)
  }

  const submitResponse = (target: ClickableElement) => () => {
    if (!user) return

    const userId = user.id

    track(clickEvent({ target }))
    setShowSurvey(false)

    if (target === ClickableElement.NpsSurveyDismiss) {
      deleteNpsSurvey({ userId })

      return
    }

    if (selectedRating === null) return

    sendNpsSurveyResponse({
      score: selectedRating,
      incomplete: false,
      comment,
      userId,
    })
  }

  function renderCommentBox() {
    return (
      <>
        <Divider />
        <DeprecatedInputTextarea
          name="netPromoterScore"
          placeholder={translate('net_promoter_score.open_question')}
          onChange={handleCommentInputChange}
        />
        <Cell
          body={
            <Button
              onClick={submitResponse(ClickableElement.NpsSurveySend)}
              styling={Button.Styling.Filled}
              text={<FormattedMessage id="net_promoter_score.submit" />}
            />
          }
        />
      </>
    )
  }

  function renderRatings() {
    const npsScale = 11
    const ratings = Array.from(Array(npsScale).keys())

    return (
      <Container styling={Container.Styling.Narrow}>
        <div className="nps-survey__ratings">
          {ratings.map(rating => (
            <Radio
              name={`rating-${rating.toString()}`}
              checked={rating === selectedRating}
              onClick={handleRatingSelect(rating)}
              text={rating.toString()}
              textPosition="bottom"
              key={rating}
              onChange={() => undefined}
            />
          ))}
        </div>
        <Spacer />
        <div className="u-flexbox u-justify-content-between">
          {/* The inputs with id="0" and id="10" are within `Radio` component */}
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="0">
            <Text
              text={
                <FormattedMessage id="net_promoter_score.score_explanation.unlikely_to_recommend" />
              }
              type={Text.Type.Caption}
              as="span"
            />
          </label>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="10">
            <Text
              text={
                <FormattedMessage id="net_promoter_score.score_explanation.likely_to_recommend" />
              }
              type={Text.Type.Caption}
              as="span"
            />
          </label>
        </div>
      </Container>
    )
  }

  const closeButton = (
    <Button
      iconName={X16}
      inline
      size={Button.Size.Medium}
      inverse
      onClick={submitResponse(ClickableElement.NpsSurveyDismiss)}
    />
  )

  return (
    showSurvey && (
      <Banner>
        <div className="u-phones-only">{closeButton}</div>
        <Cell
          prefix={breakpoints.tabletsUp ? closeButton : null}
          body={
            <Text
              as="h2"
              text={<FormattedMessage id="net_promoter_score.question" />}
              type={Text.Type.Title}
              alignment={Text.Alignment.Center}
              width={Text.Width.Parent}
            />
          }
        />
        <div className="nps-survey">
          {renderRatings()}
          <Spacer orientation={Spacer.Orientation.Vertical} />
          {showCommentBox && renderCommentBox()}
        </div>
      </Banner>
    )
  )
}

export default NetPromoterScore
