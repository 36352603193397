'use client'

import { Button, Spacer } from '@vinted/web-ui'

import { useTracking } from '@marketplace-web/shared/event-tracker'
import { useTranslate } from '@marketplace-web/shared/i18n'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { clickEvent } from '_libs/common/event-tracker/events'

const LoadMoreButton = ({ onClick }) => {
  const translate = useTranslate()
  const { track } = useTracking()

  const handleLoadMoreButtonClick = () => {
    track(clickEvent({ target: ClickableElement.FeedLoadMoreButton }))
    onClick()
  }

  return (
    <div className="u-flexbox u-align-items-center u-flex-direction-column">
      <Spacer size={Spacer.Size.Large} />
      <Button
        theme="primary"
        styling={Button.Styling.Filled}
        text={translate('feed.actions.load_more')}
        onClick={handleLoadMoreButtonClick}
        testId="feed-load-more-button"
        inline
      />
    </div>
  )
}

export default LoadMoreButton
