'use client'

import { useLocation } from '@marketplace-web/shared/browser'

import { HomepageTabModel } from 'types/models/homepage-blocks'
import useShowHomeTabs from 'pages/Home/hooks/useShowHomeTabs'

import { useHomeContext } from '../HomeProvider'

type Config = {
  navigateToTab: (tab: string) => void
  shouldShowTabs: boolean
  tabs: Array<HomepageTabModel>
  currentTab: HomepageTabModel
  currentTabName: string
}

const useTabs = (): Config => {
  const shouldShowTabs = useShowHomeTabs()
  const { tabs } = useHomeContext()

  const location = useLocation()
  const currentTabName = location.urlParams.tab as string | undefined

  const navigateToTab = (tab: string) => location.pushHistoryState(`/?tab=${tab}`)

  const currentTab = tabs.find(tab => tab.name === currentTabName) ?? tabs[0]!

  return {
    navigateToTab,
    shouldShowTabs: shouldShowTabs && tabs.length > 1,
    tabs,
    currentTab,
    currentTabName: currentTab.name,
  }
}

export default useTabs
