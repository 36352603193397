'use client'

import { ReactNode, useRef } from 'react'
import { isEqual } from 'lodash'

import { ClosetModel, VasEntryPointModel } from 'types/models'
import { HomeAdvertisement } from '@marketplace-web/domain/ads'

import HomeClosetPromotion from '../components/ClosetPromotion/HomeClosetPromotion'

type Ad = 'ad' | 'fallback-ad'
type Entity = ClosetModel | 'empty' | Ad

const isAd = (item: Entity): item is Ad => item === 'ad' || item === 'fallback-ad'
const isEmpty = (item: Entity): item is 'empty' => item === 'empty'
const isCloset = (item: Entity): item is ClosetModel => !isAd(item) && !isEmpty(item)

type Props = {
  vasEntryPoints?: Array<VasEntryPointModel>
  closets: Array<ClosetModel>
  arePromotedClosetsEnabled: boolean
  areAdsEnabled: boolean
  homepageSessionId: string
  fetchMoreClosets?: () => Promise<void>
}

type RenderProps = {
  position: number
  id: string | number
  suffix?: ReactNode
}

const useHomeClosetOrAd = ({
  vasEntryPoints,
  closets,
  areAdsEnabled,
  arePromotedClosetsEnabled,
  homepageSessionId,
  fetchMoreClosets,
}: Props) => {
  const renderedSequence = useRef<Array<Entity>>([])
  let currentIndex = 0

  const getRemainingCloset = () => {
    const remainingClosets = closets.filter(closet => {
      const wasClosetRendered = renderedSequence.current.some(value => isEqual(closet, value))

      return !wasClosetRendered
    })

    if (remainingClosets.length <= 1) fetchMoreClosets?.()

    return remainingClosets[0]
  }

  const getNewClosetOrAd = (index: number): Entity => {
    const shouldRenderCloset = index % 2 === 0

    if (shouldRenderCloset) return getRemainingCloset() ?? 'fallback-ad'

    return 'ad'
  }

  const renderCloset = (closet: ClosetModel, props: RenderProps) => {
    if (!arePromotedClosetsEnabled) return null

    return (
      <HomeClosetPromotion
        {...props}
        vasEntryPoints={vasEntryPoints}
        closet={closet}
        homepageSessionId={homepageSessionId}
      />
    )
  }

  const renderFallbackCloset = (props: RenderProps, index: number) => {
    const renderedFallback = renderedSequence.current[index]

    if (renderedFallback && isCloset(renderedFallback)) return renderCloset(renderedFallback, props)

    const fallback = getRemainingCloset()
    if (!fallback) {
      // Previously it rendered an ad, so we replace it to not render the ad again
      renderedSequence.current[index] = 'empty'

      return null
    }

    renderedSequence.current[index] = fallback

    return renderCloset(fallback, props)
  }

  const renderClosetOrAdComponent = (props: RenderProps) => {
    const index = currentIndex
    const entity = renderedSequence.current[index] ?? getNewClosetOrAd(index)
    renderedSequence.current[index] = entity

    currentIndex += 1

    if (isEmpty(entity)) return null
    if (isAd(entity)) {
      const renderFallback = () => entity === 'ad' && renderFallbackCloset(props, index)

      if (!areAdsEnabled) return renderFallback()

      return <HomeAdvertisement {...props} renderFallback={renderFallback} />
    }

    return renderCloset(entity, props)
  }

  return {
    renderClosetOrAdComponent,
  }
}

export default useHomeClosetOrAd
