'use client'

import { useEffect } from 'react'

import { useSession } from '@marketplace-web/shared/session'

import { CountryCode } from '@marketplace-web/domain/country'
import { useCookie, cookiesDataByName } from '@marketplace-web/shared/cookies'

const MAX_VISITS_FOR_PROMO = 5
const COUNTRIES_FOR_NO_PROMO = [CountryCode.Lt, CountryCode.De, CountryCode.At]

export const useSellerPromotion = (visitsCount: number) => {
  const { user } = useSession()
  const cookie = useCookie()

  const countryCodeLowerCase = (user?.country_code || '') as CountryCode
  const isNoPromoCountry = COUNTRIES_FOR_NO_PROMO.includes(countryCodeLowerCase)
  const isPromotionDisabled = isNoPromoCountry
  const isAnonymous = !user
  const isUserEligibleForPromotion = user && !user.item_count && visitsCount <= MAX_VISITS_FOR_PROMO

  const showSellerPromotion = !isPromotionDisabled && (isAnonymous || isUserEligibleForPromotion)

  useEffect(() => {
    if (!user) return
    if (!showSellerPromotion) return

    cookie.set(cookiesDataByName.seller_header_visits, String(visitsCount + 1))
  }, [user, visitsCount, showSellerPromotion, cookie])

  return {
    showSellerPromotion,
  }
}
