'use client'

import { Spacer } from '@vinted/web-ui'

import { TaxpayersSpecialVerificationSuccessModal } from '@marketplace-web/domain/taxpayers'
import InfoBanner from 'components/InfoBanner'
import { ShippingFeesAppliedBanner } from '@marketplace-web/domain/escrow-pricing'
import { Screen } from 'constants/tracking/screens'

import TopBanners from './TopBanners'

const HomeBanners = () => {
  return (
    <>
      <TopBanners />
      <TaxpayersSpecialVerificationSuccessModal />
      <InfoBanner screen={Screen.NewsFeed} theme="inverseExperimental" />
      <ShippingFeesAppliedBanner suffix={<Spacer size={Spacer.Size.X2Large} />} />
    </>
  )
}

export default HomeBanners
