import { useMemo } from 'react'

import { SINGLE_SLOT_BLOCKS } from 'constants/home'
import { HomepageBlockEntityModel } from 'types/models/homepage-blocks'

import useColumnCount from './useColumnCount'

type BlockArray = Array<HomepageBlockEntityModel>

const useRemoveEmptySlots = (blocks: BlockArray) => {
  const columnSize = useColumnCount()

  const blocksWithoutEmptySlots = useMemo(() => {
    const result: BlockArray = []
    let fullWidthBlocks: BlockArray = []
    let currentRow: BlockArray = []

    blocks.forEach(block => {
      if (SINGLE_SLOT_BLOCKS.includes(block.type)) {
        currentRow.push(block)

        const isRowFilled = currentRow.length === columnSize
        if (isRowFilled) {
          result.push(...currentRow)
          result.push(...fullWidthBlocks)
          currentRow = []
          fullWidthBlocks = []
        }

        return
      }

      const isRowFilled = currentRow.length === 0

      if (isRowFilled) {
        result.push(block)
      } else {
        fullWidthBlocks.push(block)
      }
    })

    if (currentRow.length === 0) {
      result.push(...fullWidthBlocks)
    } else {
      result.push(...currentRow)
    }

    return result
  }, [blocks, columnSize])

  return blocksWithoutEmptySlots
}

export default useRemoveEmptySlots
