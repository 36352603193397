'use client'

import { ReactNode, useRef } from 'react'
import { InView } from 'react-intersection-observer'

import { useTracking } from '@marketplace-web/shared/event-tracker'
import { ErrorBoundary, HorizontalScrollArea } from '@marketplace-web/shared/ui-helpers'
import ControlPromoBoxTracker from '_libs/common/braze/components/ControlPromoBoxTracker'
import PromoBox from '_libs/common/braze/components/PromoBox'
import { clickEvent, favouriteItemEvent, impressionEvent } from '_libs/common/event-tracker/events'
import { TrackedProductItem } from 'components/ProductItem'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { ContentSource } from 'constants/tracking/content-sources'
import { ListItemContentType } from 'constants/tracking/content-types'
import useBrazePromoBoxTracking from '_libs/common/braze/hooks/useBrazePromoBoxTracking'
import { GenericPromoBoxModel } from 'types/models'
import { PromoBoxType } from '_libs/common/braze/constants'
import { HomepageItemModel } from 'types/models/homepage-blocks'
import { logHomeMessage } from 'pages/Home/utils/observability'

import CtaItem from './CtaItem'

type Props = {
  items: Array<HomepageItemModel>
  promoBox: GenericPromoBoxModel | null
  homepageSessionId: string
  header?: ReactNode
  cta?: ReactNode
  itemTestId?: string
  handleErrorLogging?: (err: Error | null) => void
  preventLog?: boolean
  testId?: string
  itemsFullWidthAlignment?: boolean
}

const HorizontallyScrollableItems = ({
  items,
  cta,
  promoBox,
  homepageSessionId,
  header,
  itemTestId,
  handleErrorLogging,
  preventLog,
  testId,
  itemsFullWidthAlignment = false,
}: Props) => {
  const { track } = useTracking()
  const { handlePromoBoxClick, handlePromoBoxVisibility } = useBrazePromoBoxTracking(promoBox)

  const seenItemIds = useRef<Array<number>>([])

  const showPromoBox = promoBox && !promoBox.isControl

  if (!items.length) return null

  function handleFavouriteToggle({
    itemId,
    isFollowEvent,
    itemContentSource,
  }: {
    itemId: number
    isFollowEvent: boolean
    itemContentSource?: ContentSource | null
  }) {
    track(clickEvent({ target: ClickableElement.Favourite }))
    track(
      favouriteItemEvent({
        itemId,
        isFollowEvent,
        contentSource: itemContentSource,
        homepageSessionId,
      }),
    )
  }

  const handleItemVisibility = (item: HomepageItemModel, position: number) => (inView: boolean) => {
    const { id, user } = item

    if (!inView) return

    if (seenItemIds.current.includes(id)) return

    seenItemIds.current.push(id)

    if (!item.id) {
      logHomeMessage(
        `Missing item id (${item.id}) from ${item.contentSource}`,
        `Item URL: ${item.url}, user id: ${item.user.id}`,
      )
    }

    track(
      impressionEvent({
        id,
        position,
        contentType: ListItemContentType.Item,
        contentSource: item.contentSource as ContentSource,
        itemOwnerId: user.id,
        homepageSessionId,
        metadata: item.metadata,
      }),
    )
  }

  function renderPromoBox() {
    if (!showPromoBox) return null

    return (
      <div className="horizontally-scrollable-items__item-content">
        <PromoBox
          image={promoBox.imageUrl}
          color={promoBox.backgroundColor}
          url={promoBox.url}
          alt={promoBox.imageAlt}
          impressionUrl={promoBox.impressionUrl}
          onEnter={handlePromoBoxVisibility}
          onClick={handlePromoBoxClick}
          testId={`${String(itemTestId)}-${
            promoBox.type === PromoBoxType.Vinted ? 'legacy' : 'braze'
          }`}
        />
      </div>
    )
  }

  function renderItem(item: HomepageItemModel, index: number) {
    const promoBoxOffset = showPromoBox ? 1 : 0
    const position = index + 1 + promoBoxOffset

    return (
      <HorizontalScrollArea.Item
        className="horizontally-scrollable-items__item"
        key={item.id}
        testId={testId}
      >
        <InView
          data-testid={`horizontally-scrollable-item-${item.id}`}
          className="horizontally-scrollable-items__item-content"
          onChange={handleItemVisibility(item, position)}
        >
          <ControlPromoBoxTracker
            promoBox={promoBox}
            index={index}
            className="u-flex-grow u-fill-width"
          >
            <ErrorBoundary
              FallbackComponent={ErrorBoundary.ComponentError}
              preventLog={preventLog}
              onError={handleErrorLogging}
            >
              <TrackedProductItem
                item={item}
                showOwner={false}
                tracking={{
                  id: item.id,
                  contentType: ListItemContentType.Item,
                  contentSource: item.contentSource as ContentSource,
                  position,
                  homepageSessionId,
                  metadata: item.metadata,
                }}
                onFavouriteToggle={handleFavouriteToggle}
                showStatus
                testId={itemTestId && `${itemTestId}-${item.id}`}
              />
            </ErrorBoundary>
          </ControlPromoBoxTracker>
        </InView>
      </HorizontalScrollArea.Item>
    )
  }

  return (
    <>
      {header}

      <HorizontalScrollArea
        controlsScrollType={HorizontalScrollArea.ControlScrollType.Partial}
        itemsFullWidthAlignment={itemsFullWidthAlignment}
      >
        {showPromoBox && (
          <HorizontalScrollArea.Item className="horizontally-scrollable-items__item">
            {renderPromoBox()}
          </HorizontalScrollArea.Item>
        )}

        {items.map(renderItem)}

        {cta}
      </HorizontalScrollArea>
    </>
  )
}

HorizontallyScrollableItems.CtaItem = CtaItem

export default HorizontallyScrollableItems
