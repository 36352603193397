import { Hostname } from '@marketplace-web/shared/utils/server'

export enum CountryCode {
  De = 'de',
  Fr = 'fr',
  Lt = 'lt',
  Es = 'es',
  Nl = 'nl',
  Pl = 'pl',
  Cz = 'cz',
  Be = 'be',
  Lu = 'lu',
  Uk = 'uk',
  Us = 'us',
  At = 'at',
  It = 'it',
  Pt = 'pt',
  Sk = 'sk',
  Hu = 'hu',
  Se = 'se',
  Ro = 'ro',
  Dk = 'dk',
  Fi = 'fi',
  Hr = 'hr',
  Gr = 'gr',
  Ie = 'ie',
}

export enum Portal {
  De = 'de',
  DeBabies = 'de_babies',
  Fr = 'fr',
  Lt = 'lt',
  LtBabies = 'lt_babies',
  Es = 'es',
  Nl = 'nl',
  Pl = 'pl',
  Cz = 'cz',
  Uk = 'uk',
  Us = 'us',
  Sb = 'sb',
}

export const HOSTNAME_TO_COUNTRY_CODE: Record<Hostname, CountryCode> = {
  [Hostname.At]: CountryCode.At,
  [Hostname.AtSandbox]: CountryCode.At,
  [Hostname.Be]: CountryCode.Be,
  [Hostname.BeSandbox]: CountryCode.Be,
  [Hostname.Cz]: CountryCode.Cz,
  [Hostname.CzSandbox]: CountryCode.Cz,
  [Hostname.De]: CountryCode.De,
  [Hostname.DeSandbox]: CountryCode.De,
  [Hostname.Es]: CountryCode.Es,
  [Hostname.EsSandbox]: CountryCode.Es,
  [Hostname.Fr]: CountryCode.Fr,
  [Hostname.FrSandbox]: CountryCode.Fr,
  [Hostname.Hu]: CountryCode.Hu,
  [Hostname.HuSandbox]: CountryCode.Hu,
  [Hostname.Lt]: CountryCode.Lt,
  [Hostname.LtSandbox]: CountryCode.Lt,
  [Hostname.Lu]: CountryCode.Lu,
  [Hostname.LuSandbox]: CountryCode.Lu,
  [Hostname.Nl]: CountryCode.Nl,
  [Hostname.NlSandbox]: CountryCode.Nl,
  [Hostname.Pl]: CountryCode.Pl,
  [Hostname.PlSandbox]: CountryCode.Pl,
  [Hostname.Se]: CountryCode.Se,
  [Hostname.SeSandbox]: CountryCode.Se,
  [Hostname.Sk]: CountryCode.Sk,
  [Hostname.SkSandbox]: CountryCode.Sk,
  [Hostname.Pt]: CountryCode.Pt,
  [Hostname.PtSandbox]: CountryCode.Pt,
  [Hostname.Uk]: CountryCode.Uk,
  [Hostname.UkSandbox]: CountryCode.Uk,
  [Hostname.Us]: CountryCode.Us,
  [Hostname.UsSandbox]: CountryCode.Us,
  [Hostname.It]: CountryCode.It,
  [Hostname.ItSandbox]: CountryCode.It,
  [Hostname.Ro]: CountryCode.Ro,
  [Hostname.RoSandbox]: CountryCode.Ro,
  [Hostname.Dk]: CountryCode.Dk,
  [Hostname.DkSandbox]: CountryCode.Dk,
  [Hostname.Fi]: CountryCode.Fi,
  [Hostname.FiSandbox]: CountryCode.Fi,
  [Hostname.Hr]: CountryCode.Hr,
  [Hostname.HrSandbox]: CountryCode.Hr,
  [Hostname.Gr]: CountryCode.Gr,
  [Hostname.GrSandbox]: CountryCode.Gr,
  [Hostname.Ie]: CountryCode.Ie,
  [Hostname.IeSandbox]: CountryCode.Ie,
}

export const DEFAULT_COUNTRY_CODE = CountryCode.Fr
