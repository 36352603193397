'use client'

import { PropsWithChildren, useMemo } from 'react'

import { HomepageTabModel } from 'types/models/homepage-blocks'

import HomeContext from './HomeContext'

type Props = {
  homepageSessionId: string
  tabs: Array<HomepageTabModel>
  areChipsEnabled: boolean
}

const HomeProvider = ({
  children,
  homepageSessionId,
  tabs,
  areChipsEnabled,
}: PropsWithChildren<Props>) => {
  const value = useMemo(
    () => ({
      homepageSessionId,
      tabs,
      areChipsEnabled,
    }),
    [homepageSessionId, tabs, areChipsEnabled],
  )

  return <HomeContext.Provider value={value}>{children}</HomeContext.Provider>
}

export default HomeProvider
