'use client'

import { createContext } from 'react'

import useFetchClosetPromotions from '../../hooks/useFetchClosetPromotions'

const ClosetContext = createContext<ReturnType<typeof useFetchClosetPromotions>>({
  // These fallback values will never be used, as the provider is required
  closets: [],
  fetchClosetPromo: () => Promise.resolve(null),
})

export default ClosetContext
