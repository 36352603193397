import { getFirstListedBreakpoint, useBreakpoint } from '@marketplace-web/shared/breakpoints'

// These values correspond to CSS
const BREAKPOINT_TO_COLUMN_COUNT = {
  phones: 2,
  tablets: 3,
  desktops: 4,
  wide: 5,
} as const

const useColumnCount = () => {
  const activeBreakpoint = getFirstListedBreakpoint(useBreakpoint().active)
  const columnCount = BREAKPOINT_TO_COLUMN_COUNT[activeBreakpoint ?? 'desktops']

  return columnCount
}

export default useColumnCount
