'use client'

import { useContext } from 'react'

import ClosetContext from './ClosetContext'

const useClosetContext = () => {
  const context = useContext(ClosetContext)

  if (!context) throw new Error('useClosetContext must be used within a ClosetProvider')

  return context
}

export default useClosetContext
