'use client'

import { ContentSource } from 'constants/tracking/content-sources'
import { ListItemContentType } from 'constants/tracking/content-types'
import { GenericPromoBoxModel } from 'types/models/generic-promo-box'

import ItemArrangement from '../../common/ItemArrangement'
import TrackedPromoBox from './TrackedPromoBox'

type Props = {
  promoBox: GenericPromoBoxModel
} & ComponentProps<typeof ItemArrangement>

const PromoBox = (props: Props) => {
  const { promoBox, position } = props

  return (
    <ItemArrangement {...props}>
      <TrackedPromoBox
        image={promoBox.imageUrl}
        color={promoBox.backgroundColor}
        url={promoBox.url}
        impressionUrl={promoBox.impressionUrl}
        tracking={{
          id: promoBox.id,
          contentSource: ContentSource.Promo,
          contentType: ListItemContentType.Promo,
          position,
        }}
        testId="feed-legacy"
      />
    </ItemArrangement>
  )
}

export default PromoBox
