'use client'

import { PropsWithChildren, Ref, forwardRef } from 'react'

import ControlPromoBoxRenderItemWrapper from '_libs/common/braze/components/ControlPromoBoxRenderItemWrapper'
import { GenericPromoBoxModel } from 'types/models/generic-promo-box'
import { ErrorBoundary } from '@marketplace-web/shared/ui-helpers'

type Props = {
  brazePromoBoxes: Array<GenericPromoBoxModel>
  position: number
}

const ItemArrangement = (props: PropsWithChildren<Props>, ref: Ref<HTMLDivElement>) => {
  return (
    <div
      data-testid="grid-item"
      className="homepage-blocks__item homepage-blocks__item--one-fifth"
      ref={ref}
    >
      <ErrorBoundary FallbackComponent={ErrorBoundary.ComponentError}>
        <ControlPromoBoxRenderItemWrapper
          index={props.position - 1} // TODO pass `position` instead of `index`
          promoBoxes={props.brazePromoBoxes}
        >
          {props.children}
        </ControlPromoBoxRenderItemWrapper>
      </ErrorBoundary>
    </div>
  )
}

export default forwardRef(ItemArrangement)
