'use client'

import { PropsWithChildren, useMemo } from 'react'

import ClosetContext from './ClosetContext'
import useFetchClosetPromotions from '../../hooks/useFetchClosetPromotions'

const ClosetProvider = ({ children }: PropsWithChildren<unknown>) => {
  const { fetchClosetPromo, closets } = useFetchClosetPromotions()
  const value = useMemo(() => ({ fetchClosetPromo, closets }), [fetchClosetPromo, closets])

  return <ClosetContext.Provider value={value}>{children}</ClosetContext.Provider>
}

export default ClosetProvider
