import { useContext, useRef } from 'react'

import { BrazeContext } from '_libs/common/braze/containers/BrazeProvider'
import { GenericPromoBoxModel } from 'types/models'
import { ListItemContentType } from 'constants/tracking/content-types'
import { clickListItemEvent, impressionEvent } from '_libs/common/event-tracker/events'
import { ContentSource } from 'constants/tracking/content-sources'
import { useTracking } from '@marketplace-web/shared/event-tracker'

import { PromoBoxType } from '../constants'

const useBrazePromoBoxTracking = (promoBox: GenericPromoBoxModel | null) => {
  const { logCardImpression, logCardClick } = useContext(BrazeContext)
  const wasPromoBoxSeen = useRef(false)

  const { track } = useTracking()

  function handlePromoBoxVisibility() {
    if (!promoBox) return

    switch (promoBox.type) {
      case PromoBoxType.Vinted:
        if (wasPromoBoxSeen.current) break

        track(
          impressionEvent({
            id: promoBox.id,
            position: 1,
            contentType: ListItemContentType.Promo,
            contentSource: ContentSource.HomepageBlock,
          }),
        )
        break
      case PromoBoxType.BrazeSticky: {
        logCardImpression(promoBox.id)
        break
      }
      // TODO move floating promobox tracking here (PromoBoxType.Braze)
      default:
        break
    }

    wasPromoBoxSeen.current = true
  }

  function handlePromoBoxClick() {
    if (!promoBox) return

    switch (promoBox.type) {
      case PromoBoxType.Vinted:
        track(
          clickListItemEvent({
            id: promoBox.id,
            contentType: ListItemContentType.Promo,
            contentSource: ContentSource.HomepageBlock,
            position: 1,
          }),
        )
        break
      case PromoBoxType.BrazeSticky: {
        logCardClick(promoBox.id)
        break
      }
      default:
        break
    }
  }

  return {
    handlePromoBoxVisibility,
    handlePromoBoxClick,
  }
}

export default useBrazePromoBoxTracking
