'use client'

import { useLatestCallback } from '@marketplace-web/shared/ui-helpers'

import useFetchVasEntryPoints from './useFetchVasEntryPoints'
import { VasEntryPoint } from '../constants/vas-entry-point'
import useHomeClosetOrAd from './useHomeClosetOrAd'
import useClosetContext from '../containers/ClosetProvider/useClosetContext'

type Props = {
  arePromotedClosetsEnabled: boolean
  areAdsEnabled: boolean
  homepageSessionId: string
}

const useHomeInserts = (props: Props) => {
  const { data: vasEntryPoints } = useFetchVasEntryPoints(
    [VasEntryPoint.PromotedClosets],
    !props.arePromotedClosetsEnabled,
  )

  const { closets, fetchClosetPromo } = useClosetContext()

  const fetchMoreClosets = useLatestCallback(async () => {
    const setClosets = await fetchClosetPromo()
    setClosets?.()
  })

  const returnValue = useHomeClosetOrAd({
    ...props,
    fetchMoreClosets,
    vasEntryPoints,
    closets,
  })

  return returnValue
}

export default useHomeInserts
