'use client'

import { useState, useCallback, useRef } from 'react'
import { isEmpty } from 'lodash'

import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'
import { ClosetModel } from 'types/models'
import { getClosetPromotions } from 'data/api'
import { transformClosets } from 'data/transformers/closet'
import { getFirstListedBreakpoint, useBreakpoint } from '@marketplace-web/shared/breakpoints'
import { Screen } from 'constants/tracking/screens'

type Options = { fresh?: boolean }

const useFetchClosetPromotions = () => {
  const breakpoints = useBreakpoint()
  const isClosetPromotionKillswitchEnabled = useFeatureSwitch('killswitch_closet_promotion_web')

  const [closets, setClosets] = useState<Array<ClosetModel>>([])
  const hasMore = useRef(true)

  const resetClosets = () => {
    setClosets([])
    hasMore.current = true
  }

  const fetchClosetPromo = useCallback(
    async (options?: Options) => {
      if (options?.fresh) resetClosets()

      const shouldFetch = !isClosetPromotionKillswitchEnabled && hasMore.current
      if (!shouldFetch) return null

      const userIds = options?.fresh ? [] : closets.map(closet => closet.user.id)
      const breakpoint = getFirstListedBreakpoint(breakpoints.active) || 'phones'
      const breakpointToClosetPromoCount = {
        wide: 4,
        desktops: 4,
        tablets: 5,
        phones: 5,
      }
      const closetPromoCount = breakpointToClosetPromoCount[breakpoint]

      const response = await getClosetPromotions({
        perPage: closetPromoCount,
        excludedUserIds: userIds,
        screenName: Screen.Homepage,
      })

      if ('errors' in response) return null

      const transformedClosets = transformClosets(response.promoted_closets)
      hasMore.current = response.page_info.has_more

      if (isEmpty(transformedClosets)) return null

      const isFirstFetch = options?.fresh || (!closets.length && transformedClosets.length > 0)

      if (isFirstFetch && transformedClosets[0]) {
        transformedClosets[0].showBanner = true
      }

      return () => setClosets(prevArray => [...prevArray, ...transformedClosets])
    },
    [breakpoints.active, isClosetPromotionKillswitchEnabled, closets],
  )

  return { closets, fetchClosetPromo }
}

export default useFetchClosetPromotions
