'use client'

import { useEffect, useRef } from 'react'
import { Chip, Tabs, Text } from '@vinted/web-ui'
import classNames from 'classnames'

import { scrollToTop } from '@marketplace-web/shared/browser'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { userClickHomepageVertical } from '_libs/common/event-tracker/events'
import { HomepageTabModel } from 'types/models/homepage-blocks'

import useTabs from '../hooks/useTabs'
import { scrollToTab } from '../utils/tabs'
import { useHomeContext } from '../HomeProvider'

type Props = {
  onTabClick: (tabName: HomepageTabModel) => void
}

const HomeTabs = ({ onTabClick }: Props) => {
  const { track } = useTracking()
  const { currentTab, shouldShowTabs, navigateToTab, tabs } = useTabs()
  const containerRef = useRef<HTMLDivElement>(null)
  const { homepageSessionId, areChipsEnabled } = useHomeContext()

  useEffect(() => {
    scrollToTab(currentTab.name, containerRef.current)
    // Only for initial render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!shouldShowTabs) return null

  const handleClick = (tabName: string) => {
    scrollToTab(tabName, containerRef.current)
    scrollToTop()
    track(
      userClickHomepageVertical({
        homepageSessionId,
        target: tabName,
      }),
    )

    const newTab = tabs.find(tab => tab.name === tabName)
    if (!newTab) return

    onTabClick(newTab)

    if (tabName === currentTab.name) return

    navigateToTab(tabName)
  }

  const tabItems = Object.values(tabs).map(({ name, title }) => ({ title, id: name }))

  return (
    <div className={classNames('homepage-tabs', { 'homepage-tabs--chips': areChipsEnabled })}>
      <div
        className={classNames('homepage-tabs__content', {
          'homepage-tabs__content--chips': areChipsEnabled,
        })}
        ref={containerRef}
      >
        {areChipsEnabled ? (
          tabItems.map(tab => (
            <span className="u-flex-shrink-none" key={tab.id}>
              <Chip
                id={tab.id}
                text={tab.title}
                type="button"
                radius={Chip.Radius.Round}
                textType={Text.Type.Subtitle}
                onClick={() => handleClick(tab.id)}
                activated={tab.id === currentTab.name}
              />
            </span>
          ))
        ) : (
          <Tabs
            items={tabItems}
            activeItemId={currentTab.name}
            onClick={item => handleClick(item.id)}
          />
        )}
      </div>
    </div>
  )
}

export default HomeTabs
