'use client'

import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'
import { useAbTest } from '@marketplace-web/shared/ab-tests'

import Banner from './Banner'
import DismissibleStickyBanner from './DismissibleStickyBanner'

type Props = {
  suffix?: JSX.Element
  prefix?: JSX.Element
}

const ShippingFeesAppliedBanner = (props: Props) => {
  const shippingFeesInfoBannerV1 = useAbTest('info_banner_shipping_fees_apply_feed')
  const isStickyShippingInfoBannerOn = useFeatureSwitch('shipping_info_banner_v2_toggle')

  if (isStickyShippingInfoBannerOn) return <DismissibleStickyBanner {...props} />
  if (shippingFeesInfoBannerV1) return <Banner {...props} />

  return null
}

export default ShippingFeesAppliedBanner
