'use client'

import { useRef } from 'react'

import { TrackedProductItem } from 'components/ProductItem'
import { ListItemContentType } from 'constants/tracking/content-types'
import { clickEvent, favouriteItemEvent, impressionEvent } from '_libs/common/event-tracker/events'
import { GenericPromoBoxModel } from 'types/models/generic-promo-box'
import { HomepageItemModel } from 'types/models/homepage-blocks'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { ContentSource } from 'constants/tracking/content-sources'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { logHomeMessage } from 'pages/Home/utils/observability'

import useVisible from '../../hooks/useVisible'
import ItemArrangement from '../../common/ItemArrangement'

type Props = {
  item: HomepageItemModel
  position: number
  homepageSessionId: string
  brazePromoBoxes: Array<GenericPromoBoxModel>
}

const Item = (props: Props) => {
  const isSeen = useRef(false)
  const { track } = useTracking()

  const { item, position, homepageSessionId } = props

  const onVisible = () => {
    if (isSeen.current) return

    if (!item.id) {
      logHomeMessage(
        `Missing item id (${item.id}) from ${item.contentSource}`,
        `Item URL: ${item.url}, user id: ${item.user.id}`,
      )
    }

    track(
      impressionEvent({
        id: item.id,
        position,
        contentType: ListItemContentType.Item,
        contentSource: item.contentSource,
        itemOwnerId: item.user.id,
        homepageSessionId,
        metadata: item.metadata,
      }),
    )

    isSeen.current = true
  }

  const ref = useVisible(onVisible)

  const handleFavouriteToggle = ({
    itemId,
    isFollowEvent,
    itemContentSource,
  }: {
    itemId: number
    isFollowEvent: boolean
    itemContentSource?: ContentSource | null
  }) => {
    track(clickEvent({ target: ClickableElement.Favourite }))
    track(
      favouriteItemEvent({
        itemId,
        isFollowEvent,
        homepageSessionId,
        contentSource: itemContentSource,
      }),
    )
  }

  return (
    <ItemArrangement ref={ref} {...props}>
      <TrackedProductItem
        tracking={{
          id: item.id,
          contentType: ListItemContentType.Item,
          contentSource: item.contentSource,
          position,
          homepageSessionId,
          metadata: item.metadata,
        }}
        testId="feed-item"
        item={item}
        showStatus
        showStatusAsBadge
        onFavouriteToggle={handleFavouriteToggle}
      />
    </ItemArrangement>
  )
}

export default Item
