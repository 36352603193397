'use client'

import BrazePromoBoxComponent from '_libs/common/braze/components/BrazePromoBox'
import { GenericPromoBoxModel } from 'types/models/generic-promo-box'

import ItemArrangement from '../../common/ItemArrangement'

type Props = {
  position: number
  promoBox: GenericPromoBoxModel
  brazePromoBoxes: Array<GenericPromoBoxModel>
}

const BrazePromoBox = (props: Props) => {
  return (
    <ItemArrangement {...props}>
      <BrazePromoBoxComponent promoBox={props.promoBox} />
    </ItemArrangement>
  )
}

export default BrazePromoBox
