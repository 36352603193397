'use client'

import { X24 } from '@vinted/monochrome-icons'
import { Button, Cell, Dialog, Icon, Image, Navigation, Spacer, Text } from '@vinted/web-ui'

import { useTranslate } from '@marketplace-web/shared/i18n'
import { FallbackComponentProps, useImageAssetsUrl } from '@marketplace-web/shared/ui-helpers'
import { CATALOG_URL } from 'constants/routes'

type Props =
  | {
      onCloseClick: () => void
      enableClose: true
    }
  | ({
      enableClose?: false
    } & FallbackComponentProps)

const ErrorModal = (props: Props) => {
  const translate = useTranslate()

  const assetUrl = useImageAssetsUrl()

  return (
    <Dialog show closeOnOverlay={false} testId="homepage-error-modal">
      {props.enableClose && (
        <Navigation
          theme="transparent"
          right={
            <Button
              styling={Button.Styling.Flat}
              icon={<Icon name={X24} />}
              inline
              title={translate('common.a11y.actions.dialog_close')}
              onClick={props.onCloseClick}
            />
          }
        />
      )}
      <Cell>
        <div className="u-flexbox u-justify-content-center">
          <Image src={assetUrl('exclamation-red.svg')} size={Image.Size.Large} />
        </div>
        <Spacer size={Spacer.Size.Large} />
        <Text
          text={translate('homepage.error.title')}
          width={Text.Width.Parent}
          alignment={Text.Alignment.Center}
          type={Text.Type.Heading}
          as="h2"
        />
        <Spacer size={Spacer.Size.Large} />
        <Text
          text={translate('homepage.error.body')}
          width={Text.Width.Parent}
          alignment={Text.Alignment.Center}
          type={Text.Type.Title}
          as="p"
        />
      </Cell>
      <Cell>
        <Button
          text={translate('homepage.error.cta')}
          url={CATALOG_URL}
          styling={Button.Styling.Filled}
        />
      </Cell>
    </Dialog>
  )
}

export default ErrorModal
